<template>
  <div class="news-reaction">
    <v-row>
      <v-col class="d-flex flex-row" cols="3">
        <div class="reaction-container">
          <div class="reaction">
            <img :src="require('@/assets/icons/reactions/like.svg')" alt="" />
          </div>
        </div>
        <span class="count">{{ like }}</span>
      </v-col>

      <v-col class="d-flex flex-row" cols="3">
        <div class="reaction-container">
          <div class="reaction">
            <img :src="require('@/assets/icons/reactions/laugh.svg')" alt="" />
          </div>
        </div>
        <span class="count">{{ laugh }}</span>
      </v-col>

      <v-col class="d-flex flex-row" cols="3">
        <div class="reaction-container">
          <div class="reaction">
            <img :src="require('@/assets/icons/reactions/heart.svg')" alt="" />
          </div>
        </div>
        <span class="count">{{ heart }}</span>
      </v-col>

      <v-col class="d-flex flex-row" cols="3">
        <div class="reaction-container">
          <div class="reaction">
            <img :src="require('@/assets/icons/reactions/sad.svg')" alt="" />
          </div>
        </div>
        <span class="count">{{ sad }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * News: Reactions
 * ==================================================================================
 **/

import { mapState, mapActions } from 'vuex'
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {},
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    reactionSummary: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: new Form({}),
      like: this.reactionSummary.like ? this.reactionSummary.like : 0,
      laugh: this.reactionSummary.laugh ? this.reactionSummary.laugh : 0,
      heart: this.reactionSummary.heart ? this.reactionSummary.heart : 0,
      sad: this.reactionSummary.sad ? this.reactionSummary.sad : 0,
    }
  },

  computed: {
    ...mapState({
      news: (state) => state.news.newsDetails,
    }),
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions({}),
    init() {},
  },
}
</script>
<style lang="scss">
.reaction-container {
  background: #fcd3b8;
  padding: 3px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin: 5px;

  .reaction {
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  img {
    display: block;
    height: 60%;
    position: absolute;
    margin: auto;
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
}

span.count {
  position: relative;
  top: 32%;
  margin-left: 10px;
}
</style>
